<template>
  <!-- MandagMorgen -->
  <NuxtLink
    v-if="false"
    :to="{ name: 'paper', params: { paper: paper.slug } }"
    class="block"
    :class="[isLarge ? 'max-w-full' : 'w-20']"
  >
    <div
      class="rounded-lg overflow-hidden h-60 md:h-90 p-5 bg-contain bg-no-repeat bg-bottom"
      :style="[
        { backgroundColor: getMmPaperColor(paper.slug) },
        {
          backgroundImage: `url(https://legacy.mm.dk/images/altinget8/covers/paper-cover-${paper.id}.png)`,
        },
      ]"
    >
      <div class="list-title-l">{{ paper.name }}</div>
    </div>
  </NuxtLink>

  <!-- Altinget -->
  <div
    v-else
    class="flex flex-col h-full"
    :class="[isLarge ? 'max-w-full' : 'w-24']"
  >
    <NuxtLink :to="{ name: 'paper', params: { paper: paper.slug } }">
      <div
        class="flex flex-col h-full"
        :class="[type === 'slider' ? 'body-m' : 'body-xs']"
      >
        <div
          class="bg-blue-light rounded-lg overflow-hidden"
          :class="isLarge ? 'max-w-full' : 'w-24 h-24'"
        >
          <template v-if="isLarge">
            <div class="mb-2 p-4 flex items-center">
              <img
                class="w-5 h-5 inline-block mr-2"
                :alt="`Altinget ${paper.name}`"
                src="/A_Logomark.svg"
              /><span class="inline-block list-title-xxxs text-white">{{
                paper.name
              }}</span>
            </div>
            <NuxtPicture
              :src="`https://${config.public.site.legacyurl}/images/altinget8/covers/paper-cover-${paper.id}.png`"
              :alt="`Altinget ${paper.name}`"
              width="520"
              height="520"
              sizes="sm:520px"
              format="webp"
              class="w-full h-auto max-w-full aspect-square -mt-6"
            />
          </template>

          <NuxtPicture
            v-else
            :src="`https://${config.public.site.legacyurl}/images/altinget8/covers/paper-cover-${paper.id}-96.png`"
            :alt="`Altinget ${paper.name}`"
            format="webp"
            sizes="sm:96px"
            width="96"
            height="96"
            class="w-full h-auto max-w-full aspect-square"
          />
        </div>
        <div :class="isLarge ? 'list-title-s text-center mt-2' : 'mt-1'">
          {{ paper.name }}
        </div>
        <div v-if="isLarge" class="mt-2 body-xs text-center">
          {{ paper.description }}
        </div>
      </div>
    </NuxtLink>
    <div
      v-if="isLarge && type !== 'slider' && !hasSubscription"
      class="text-center mt-auto"
    >
      <NuxtLink :to="{ name: 'subscription' }">
        <CommonButton
          bg="bg-blue"
          text="text-white"
          border="border-none"
          class="mt-5"
          size="medium"
        >
          {{ $t('CreateProfile') }}
        </CommonButton>
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
import { NormalizedPaperSagaDto } from '~/typesManual/normalized'

const config = useRuntimeConfig()

const userStore = useUserStore()

const props = withDefaults(
  defineProps<{
    paper: NormalizedPaperSagaDto
    type?: string
  }>(),
  {
    type: 'slider',
  }
)

const hasSubscription = computed(() => {
  if (props.paper.id === undefined) {
    return false
  }
  return userStore.hasSubscription(props.paper.id)
})

const isLarge = computed(
  () =>
    props.type === 'large' ||
    props.type === 'default' ||
    props.type === 'slider'
)
</script>
